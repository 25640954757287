import { useEffect, useRef, useState } from 'react'
import { Icon, Input, KeyCode, Divider } from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import type { GuestsType } from 'src/constants/user'
import {
  maxAdultGuests,
  maxKidGuests,
  minAdultGuests,
  minKidGuests,
} from 'src/constants/user'
import { getTotalGuests } from 'src/utils'

const PersonIcon = (
  <span className='c-new-forest'>
    <Icon name='personOutline' />
  </span>
)

interface GuestConfigurationPopoverProps {
  guests: GuestsType
  onChange(guests: GuestsType): void
}

export const GuestConfigurationPopover = ({
  guests,
  onChange,
}: GuestConfigurationPopoverProps) => {
  const displayValue = getTotalGuests({ guests })
  // Render the popover inside this parent element because otherwise it will be outside of the modal
  const parentRef = useRef(null)
  const triggerRef = useRef(null)

  const [adults, kids] = guests
  const [isOpen, setIsOpen] = useState(false)
  const { lucencyNumber } = useLucencyNumber()

  useEffect(() => {
    if (parentRef.current && isOpen) {
      // focus on the first button
      const firstButton = parentRef.current?.querySelector(
        'button:not([disabled]), button:not([aria-disabled="true"])'
      )
      firstButton?.focus()
    }
  }, [isOpen])

  const updateGuests = (adults, kids) => {
    lucencyNumber({
      rooms: [{ adults, kids }],
    })
    onChange([adults, kids])
  }

  const hasMinAdults = adults <= minAdultGuests
  const removeAdult = () => {
    if (!hasMinAdults) updateGuests(adults - 1, kids)
  }

  const hasMaxAdults = adults >= maxAdultGuests
  const addAdult = () => {
    if (!hasMaxAdults) updateGuests(adults + 1, kids)
  }

  const hasMaxKids = kids >= maxKidGuests
  const addChild = () => {
    if (!hasMaxKids) updateGuests(adults, kids + 1)
  }

  const hasMinKids = kids <= minKidGuests
  const removeChild = () => {
    if (!hasMinKids) updateGuests(adults, kids - 1)
  }

  const toggleOpen = () => setIsOpen(!isOpen)

  const onKeyboardClose = e => {
    if (e.key === KeyCode.ESC) {
      e.preventDefault()
      setIsOpen(false)
      // return focus to the trigger
      triggerRef.current?.focus()
    }
  }

  const onKeyboardOpen = e => {
    if (e.key === KeyCode.ENTER) {
      e.preventDefault()
      setIsOpen(true)
    }
  }

  const onClickOutside = () => setIsOpen(false)

  return (
    // this is needed to manage focus and control when the popover opens/closes
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div ref={parentRef} onKeyDown={onKeyboardClose}>
      <Popover
        containerClassName='z-2'
        content={
          <div className='rounded-2 shadow-1 border-1 border-grey-300 mt-2 border-solid bg-white p-6'>
            <div className='flex items-center justify-between'>
              <div className='grow-1 mr-2'>
                <p className='text-subtitle-1 m-0 text-sm'>Adults:</p>
                <p className='text-small-text m-0'>Ages 18 or above</p>
              </div>
              <NewIconButton
                aria-disabled={hasMinAdults.toString()}
                aria-label='Remove adult'
                onClick={removeAdult}
              >
                <Icon name='removeCircleOutline' />
              </NewIconButton>
              <span className='w-[2ch] text-center'>{adults}</span>
              <NewIconButton
                autoFocus
                aria-disabled={hasMaxAdults.toString()}
                aria-label='Add adult'
                onClick={addAdult}
              >
                <Icon name='addCircleOutline' />
              </NewIconButton>
            </div>
            <Divider className='my-3.5' />
            <div className='flex items-center justify-between'>
              <div className='grow-1 mr-2'>
                <p className='text-subtitle-1 m-0 text-sm'>Children:</p>
                <p className='text-small-text m-0'>Ages 0-17</p>
              </div>
              <NewIconButton
                aria-disabled={hasMinKids.toString()}
                aria-label='Remove child'
                onClick={removeChild}
              >
                <Icon name='removeCircleOutline' />
              </NewIconButton>
              <span className='w-[2ch] text-center'>{kids}</span>
              <NewIconButton
                aria-disabled={hasMaxKids.toString()}
                aria-label='Add child'
                onClick={addChild}
              >
                <Icon name='addCircleOutline' />
              </NewIconButton>
            </div>
          </div>
        }
        isOpen={isOpen}
        parentElement={parentRef.current ?? undefined}
        positions='bottom'
        reposition={true}
        onClickOutside={onClickOutside}
      >
        <div className='w-full'>
          <Input
            fullWidth
            readOnly
            label='Guests'
            ref={triggerRef}
            slotBefore={PersonIcon}
            value={displayValue}
            onClick={toggleOpen}
            onKeyDown={onKeyboardOpen}
          />
        </div>
      </Popover>
    </div>
  )
}

const NewIconButton = props => (
  <button
    {...props}
    className='c-forest-light rd-full disabled:c-grey-600 aria-disabled:c-grey-600 border-none bg-transparent p-2 hover:bg-black/5 aria-disabled:hover:bg-none [&>i]:block'
  />
)
