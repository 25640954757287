import { useEffect, useState } from 'react'
import { Button, DateRangePicker } from '@travelpass/design-system'
import dayjs from 'dayjs'
import type { DateInterval } from 'react-day-picker'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useLucencyNumber } from 'src/config/analytics/lucencyUtils'
import { hotelResultsPath } from 'src/constants'
import type { DatesType, GeocoderType, GuestsType } from 'src/constants/user'
import {
  initialDates,
  initialGeocoder,
  initialGuests,
} from 'src/constants/user'
import { HotelResultsSearchParams } from 'src/pages/hotels/results/hotelResultsConstants'
import { constructGuests, validateDates } from 'src/utils'
import { constructArguments } from './searchHotelsUtils'
import type { GeocoderProps } from '../Geocoder'
import { Geocoder } from '../Geocoder'
import { GuestConfigurationPopover } from '../Guests/GuestConfigurationPopover'

interface SearchHotelsProps {
  checkSearchParams?: boolean
  checkTypes?: GeocoderProps['checkTypes']
  dates?: DatesType
  focusOnGeocoderInput?: GeocoderProps['focusOnInput']
  geocoder?: GeocoderType
  onChangeDates?: (arg0: DatesType) => void
  onChangeGeocoder?: (arg0: GeocoderType) => void
  onClose?: () => void
}

interface SearchHotelsSubmitArguments {
  dates: DatesType
  geocoder: GeocoderType
  guests: GuestsType
}

const maxDate = dayjs().add(1, 'year').toDate()

const SearchHotels = ({
  checkSearchParams = false,
  checkTypes = false,
  dates = initialDates,
  focusOnGeocoderInput = true,
  geocoder = initialGeocoder,
  onChangeDates = () => {},
  onChangeGeocoder = () => {},
  onClose = () => {},
}: SearchHotelsProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const adults = searchParams.get(HotelResultsSearchParams.adults)
  const kids = searchParams.get(HotelResultsSearchParams.kids)
  const [isInvalid, setIsInvalid] = useState(false)
  const [guests, setGuests] = useState(() =>
    checkSearchParams ? constructGuests({ adults, kids }) : initialGuests
  )
  const isResultsRoute = location.pathname === hotelResultsPath
  const willUpdate = isResultsRoute
  const { lucencyNumber } = useLucencyNumber()

  useEffect(() => {
    const [arrival, departure] = dates
    const { validArrival, validDeparture } = validateDates({
      arrival,
      departure,
    })
    onChangeDates([validArrival, validDeparture])
  }, [])

  const onButtonClick = () => {
    if (geocoder === initialGeocoder) {
      setIsInvalid(true)
    } else {
      const constructedArguments = constructArguments({
        dates,
        geocoder,
        guests,
        searchParams,
        willUpdate,
      })
      navigate(constructedArguments)
      onClose()
    }
  }

  const onSelect = range => {
    // track check-in and check-out dates to lucency
    lucencyNumber({
      checkin: range.from,
      checkout: range?.to,
    })
    const updatedDates: DatesType = [range.from, range?.to]
    onChangeDates(updatedDates)
  }

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      setIsInvalid(false)
    }
    onChangeGeocoder(updatedGeocoder)
  }

  const onGuestsChange = (updatedGuests: GuestsType) => setGuests(updatedGuests)
  const oneMonthIntervalMatcher: DateInterval = {
    after: dates[0].add(30, 'day').toDate(),
    before: dates[0].subtract(30, 'day').toDate(),
  }

  return (
    <div className='color-grey900 grid grid-flow-dense grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-2'>
      <div className='lg:col-span-3'>
        <Geocoder
          checkTypes={checkTypes}
          errorText={!!isInvalid && 'Please select a destination.'}
          focusOnInput={focusOnGeocoderInput}
          geocoder={geocoder}
          isInvalid={isInvalid}
          label='Destination or Hotel Name'
          onResult={onGeocoderChange}
        />
      </div>
      <div>
        <GuestConfigurationPopover guests={guests} onChange={onGuestsChange} />
      </div>
      <div className='lg:col-span-2'>
        <DateRangePicker
          disabled={!dates[1] && oneMonthIntervalMatcher}
          initialFocus={true}
          label='Check in – Check out'
          numberOfMonths={1}
          selected={{
            from: dates[0],
            to: dates[1],
          }}
          toDate={maxDate}
          onSelect={onSelect}
        />
      </div>
      <div className='flex flex-col items-center pt-5 lg:col-span-3'>
        <Button size='large' startIcon='search' onClick={onButtonClick}>
          Search Hotels
        </Button>
      </div>
    </div>
  )
}

export { SearchHotels }
export type { SearchHotelsProps }
